<template>
  <div class="container bg:whitesmoke!">
    <section class="hero is-primary is-small">
      <div class="hero-body">
        <p v-if="profile === 'Staff'" class="title2">{{ userDetails.Title }} {{ userDetails.LastName }}</p>
        <p v-if="profile === 'Student'" class="font:2em">Hi {{ userDetails.FirstName }}!</p>
        <p class="subtitle">Good {{ timeSlot }}</p>
      </div>
    </section>

    <RTT />

    <div id="lunch" class="container" v-if="canOrderLunch">
        <div class="columns">
          <div class="column is-12-desktop has-text-centered">
            <button class="button is-info" @click="orderLunch()">
              Order Lunch
            </button>
          </div>
        </div>
      </div>

  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
//import api from 'helpers/apicalls';

//import { format } from "date-fns";
import RTT from "Components/home/rtt.vue"

export default {
  name: "home",

  components: {
    RTT
  },

  data: function () {
    return {
      user: firebase.auth().currentUser,
      students: [],
    };
  },
  computed: {
    session() {
      return "hello";
    },

    isStaff() {
      return this.$store.getters.isStaff;
    },

    userDetails() {
      console.log({data: this.$store.state.currentDomain, msg: "current domain"})
      console.log(this.$store.getters.currentUserDetails)
      return this.$store.getters.currentUserDetails
    },

    profile() {
      return this.$store.getters.currentUserDetails.Profile ?? undefined;
    },

    timeSlot() {
      return this.$store.state.timeOfDay;
    },

    timetable() {
      //console.log(this.$store.state.currentTimeTable)
      return this.$store.state.currentTimeTable;
    },

    canOrderLunch() {

      if (this.$store.getters.isTimetabled) {
        return true
      }

      if (!this.isStaff) {
        return false
      }





      console.log(this.$store.getters.currentDomainDetails)

      console.log(["menu?",this.$store.getters.currentDomainDetails?.menu])

      return  (this.$store.getters.currentDomainDetails?.menu ?? false) ? true : false


    },



    currentDomain() {
      return this.$store.state.currentDomain;
    },
  },
  methods: {
    test: async function () {
      try {
        alert(JSON.stringify([this.timetable, this.user]));
      } catch (error) {
        alert("Wibble: " + error);
      }
    },
    orderLunch() {
      this.$router.push("/school/lunch/");
    },
    getClassForPeriod(periodIndex) {
      let period = this.$store.state.currentTimeTable.data[periodIndex];

      let today = new Date().toISOString();

      if (period.date !== today.slice(0, 10)) {
        return "";
      }

      let time = today.slice(11, 19);

      if (time < period.startTime) {
        return "";
      }

      if (time > period.endTime) {
        return "";
      }


      return "notification is-info";
    },
  },

  beforeMount() {
    this.$store.dispatch("updatePersonalTimetable");
  },
};
</script>
