<template>
  <div class="container has-background-white-ter">
    <div id="users-timetable" class="container">
      <div class="container has-background-white is-rounded p-2 mb-1">
        <nav class="level is-rounded">
          <!-- Left side -->

          <div class="level-item is-rounded">
            <h4 class="title is-4 is-spaced">
              Your Timetable
              <span v-if="timetable && timetable.length">
                for {{ timetableDay }} ({{
                  timetable[0].timetableDayName
                }})</span
              >
            </h4>
          </div>

          <!-- Right side -->
          <div
            v-if="$store.getters.isStaff || $store.getters.isStudent"
            class="level-item"
          >
            <p class="level-item">
              <router-link
                class="button"
                :to="{ path: '/school/timetable/mine/' }"
              >
                Full Timetable
              </router-link>
            </p>
          </div>
        </nav>
      </div>
      <div class="container">
        <RTT v-if="timetable && timetable.length" :timetable="timetable"></RTT>
        <div v-else class="columns">
          <div class="column">
            <div class="box">
              <article class="media">
                <div class="media-left is-size-1">
                  <Icon icon="mdi:timetable" class="is-64x64" />
                </div>
                <div class="media-content">
                  <div class="content">
                    <p v-if="timetable && timetable.length === 0">
                      No timetable for today
                    </p>
                    <p v-else>Loading your timetable...</p>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2";
import format from "date-fns/format";
import RTT from "Components/school/rtt.vue";

export default {
  name: "home",

  components: {
    Icon,
    RTT,
  },

  computed: {
    timetable() {
      console.log(this.$store.getters.currentTimeTable);
      return this.$store.getters.currentTimeTable;
    },

    timetableDay() {
      if (this.$store.getters.currentTimeTable[0]) {
        let date = new Date(this.$store.getters.currentTimeTable[0].date);
        //console.log(date)
        return format(date, "cccc, do LLL yyyy");
      }
      return "";
    },
  },

  methods: {
    getClassForPeriod(periodIndex) {
      let period = this.$store.getters.currentTimeTable.data[periodIndex];

      let today = format(new Date(), "yyyy-MM-dd HH:mm");

      if (period.date !== today.slice(0, 10)) {
        return "";
      }

      let time = today.slice(-5);

      if (time < period.startTime) {
        return "";
      }

      if (time > period.endTime) {
        return "";
      }

      return "notification is-info";
    },
  },
};
</script>
